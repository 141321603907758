import {Component, OnInit, Injectable, Optional, Inject, Input, Renderer2 } from '@angular/core';

import {
    TemplateExporterService
} from "@/angular-blocworx/components/scanStation/template-exporter/template-exporter.service";

import {ScanStationService} from "@/angular-blocworx/components/scanStation/scan-station-service";

@Component({
    selector: 'template-exporter',
    templateUrl: 'angular-blocworx/components/scanStation/template-exporter/template-exporter.html',
    styleUrls: ['angular-blocworx/components/scanStation/template-exporter/template-exporter.component.scss']
})

@Injectable({
    providedIn: 'root'
})

export class TemplateExporter implements OnInit {

    @Input() scanStation;
    @Input() main;
    @Input() exportFromData;
    @Input() rowId;
    @Input() rowKey;
    @Input() exportSource;
    @Input() dataEditHistoryId;
    showPDFEXport: boolean = false;
    templateFileList = null;
    showTemplateBlock = false;
    selectedFileToDownload = null;
    selectedFileOutputFileName = null;
    waitingForTemplate = false;
    downloadUrl = null;
    filename = 'mockup.docx';
    emailAddresses: string = '';


    constructor(
        @Inject(TemplateExporterService) private templateExportService: TemplateExporterService,
        @Inject(Renderer2) private renderer: Renderer2,
        @Inject(ScanStationService) private scanStationService: ScanStationService
    ) {

    }

    ngOnInit() {
    }

    /**
     * This is the first initial action when you click the pdf or docx icon
     * It either gets a chosen template automatically and triggers the down or gives the options of what template you want to download
     * @param pdfOrWord
     */
    pdfOrDocxIconClickAction = async (pdfOrWord) => {

        this.scanStation.pdfOrWord = pdfOrWord;

        if (this.scanStation.scanStationObj.stationDetails.automaticallyChooseTemplate == 1) {
            this.templateDownload(this.scanStation.id, 'automatically-chosen-template', pdfOrWord, this.rowId);
        } else {

            await this.getScanStationTemplates();

            await this.buildMailToAddresses('in-data');

            this.showPDFEXport = true;
        }

    }

    /**
     * This function gets all the template in this bloc
     */
    getScanStationTemplates = async () => {
        try {

            if (this.templateFileList == null) {
                this.templateFileList = await this.templateExportService.loadScanStationTemplates(this.scanStation.id);
            }

            // load the template file list
            let i = 0;
            for (let templateFile of this.templateFileList) {
                if (i == 0) {
                    await this.setSelectedDownloadDetails(templateFile);
                }
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function will close the template block
     */
    closeTemplateBlock = () => {
        this.showTemplateBlock = false;
    }

    /**
     * This will be the toggle action for the main red button, so if
     * one click is done it will open, next time will be getting
     * the opposite value, closing the block.
     */
    toggleTemplateBlock = () => {
        this.showTemplateBlock = this.showTemplateBlock === null ? true : !this.showTemplateBlock;
    }

    /**
     * This function sets up the details for the file we have chosen to download, including the file name
     * @param templateFile
     */
    setSelectedDownloadDetails = async (templateFile) => {
        this.selectedFileToDownload = templateFile.exported_filename;
        if (templateFile.output_filename == null || templateFile.output_filename == '') {
            this.selectedFileOutputFileName = null;
        } else {
            this.selectedFileOutputFileName = templateFile.output_filename;

            // replace the [BW]field_slug[/BW] in the file name with data
            let dataObject = [];

            // we check if we are exporting from the top of the bloc, or from inside the results
            // this can be checked by seeing if the rowId exists in the component or not
            if (this.rowId == null) {
                dataObject = Object.entries(this.scanStation.scanStationObj.dataToAdd);
            } else if (this.rowId != null && this.rowKey != null) {
                let singleRecordObject = await this.scanStationService.getSingleRecordByRecordId(this.rowId);
                dataObject = Object.entries(singleRecordObject.data);
            }

            for (let [index, val] of dataObject) {
                this.selectedFileOutputFileName = this.selectedFileOutputFileName.replaceAll(`[BW]${index}[/BW]`, val);
            }

        }

    }

    /**
     * This will download a template. It also includes the option to email it directly to someone instead
     * It also contains the logic to to get the automatically chosen template
     */
    templateDownload = async (scanStationId, chosenTemplateFile, pdfOrWord, rowId = null, emailAddresses = null) => {
        try {

            if(emailAddresses != null && emailAddresses.length > 0) {

                const confirmation = window.confirm('Are you sure you want to email this file?');

                if (!confirmation) {
                    return false;
                }
            }

            let dataToAdd = this.scanStation.scanStationObj.dataToAdd;

            this.waitingForTemplate = true;
            try {
                if (chosenTemplateFile == 'automatically-chosen-template') {
                    let response = await this.templateExportService.getAutomaticallyChosenTemplateFile(scanStationId, dataToAdd, rowId, this.dataEditHistoryId, null);
                    chosenTemplateFile = response.templateFile.exported_filename;

                    this.setSelectedDownloadDetails(response.templateFile);
                }

                let response = await this.templateExportService.templateDownload(scanStationId, dataToAdd, chosenTemplateFile, pdfOrWord, rowId, emailAddresses, this.dataEditHistoryId);
                if (emailAddresses == null) {
                    let blob = new Blob([response], {
                        type: 'application/octet-stream'
                    });

                    let URL = window.URL || window.webkitURL;

                    this.downloadUrl = URL.createObjectURL(blob);

                    if (pdfOrWord != 'pdf' && this.selectedFileOutputFileName == null && chosenTemplateFile != 'automatically-chosen-template') {
                        this.filename = this.scanStation.scanStationObj.stationName + '.' + chosenTemplateFile.split('.').pop();
                    } else if (pdfOrWord != 'pdf' && this.selectedFileOutputFileName != null && chosenTemplateFile != 'automatically-chosen-template') {
                        this.filename = this.selectedFileOutputFileName + '.' + chosenTemplateFile.split('.').pop();
                    } else if (pdfOrWord == 'pdf' && this.selectedFileOutputFileName != null) {
                        this.filename = this.selectedFileOutputFileName + '.pdf';
                    } else {
                        this.filename = this.scanStation.scanStationObj.stationName + '.pdf';
                    }

                    let element = await document.getElementById('downloadPdfBtn');

                    this.waitingForTemplate = false;

                    this.showPDFEXport = false;

                    this.renderer.setAttribute(element, 'href', this.downloadUrl);
                    this.renderer.setAttribute(element, 'download', this.filename);

                    element.click();
                } else {
                    this.waitingForTemplate = false;
                    alert('Attachment has been successfully sent.');

                }
            } catch (err) {
                alert('Something has gone wrong with this export');
                console.log(err);
                this.waitingForTemplate = false;
                return false;
            }


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Main docx filename
     */
    getFilename = () => {
        return this.filename;
    }

    /**
     * This gets data from the dataToAdd, which is the current data in the bloc and then checks using field parameters
     * if any of the values are going to be used for the mail feature.
     * @param source
     */

    buildMailToAddresses = async (source) => {
        try {
            this.emailAddresses = '';

            let dataSource = this.scanStation.scanStationObj.dataToAdd;

            if (source == 'in-data') {
                dataSource = this.scanStation.scanStationObj.liveData[this.rowKey];
            }

            this.emailAddresses = this.templateExportService.buildMailToAddresses(dataSource, this.scanStation.scanStationObj.scanStationFields);

        } catch (e) {
            console.log(e);
        }
    }


}
