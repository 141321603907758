import {Component, OnInit, Injectable, Optional, Inject, Input} from '@angular/core';
import {
    DataEditHistoryService
} from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history.service";
import {MatDialog} from "@angular/material/dialog";
import {
    DataEditHistoryDialog
} from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history-dialog.component";
import {OverlayContainer} from '@angular/cdk/overlay';
import {ResultsBoxService} from "@/angular-blocworx/components/fields/results-box/results-box.service";


@Component({
    selector: 'something-else',
    templateUrl: 'angular-blocworx/components/scanStation/data-edit-history/data-edit-history.html'
})

@Injectable({
    providedIn: 'root'
})

export class DataEditHistory implements OnInit {

    @Input() scanStation;
    @Input() main;
    dataEditHistoryForSingleRecord: any;
    currentResultsBoxRecords: any;

    constructor(
        @Inject(DataEditHistoryService) private dataEditHistoryService: DataEditHistoryService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer,
        @Inject(ResultsBoxService) private resultsBoxService: ResultsBoxService
    ) {

        this.dataEditHistoryService.setTriggerClearEditHistoryBoxData(this.clearEditHistoryData.bind(this));
    }

    ngOnInit() {
    }

    clearEditHistoryData = () => {
        this.dataEditHistoryForSingleRecord = null;
    }


    /**
     * This gets the edit history for a single record by the stationDataID which is the id of the record in scan_station_data
     */
    getDataEditHistory = async () => {
        this.dataEditHistoryForSingleRecord = await this.dataEditHistoryService.getDataEditHistoryForSingleRecord(this.scanStation.scanStationObj.stationDataID);
    }

    /**
     * This checks if are supposed to show the edit history button or not based on bloc settings
     */
    showDataEditHistoryButton = () => {
        return (this.scanStation.scanStationObj.stationDataID != null && this.scanStation.scanStationObj.stationDetails.showDataEditHistory == 1)
    }

    /**
     * This is simple helper function to create an array from an object
     * TODO: This should be moved to a helper service
     * @param object
     */
    convertObjectToArray(object: object): any[] {
        if (object != null) {
            return Object.values(object);
        }
    }

    /**
     * This will open the pop up which contains all the data for that record, including results box data
     * @param dataEditHistoryId
     */
    viewDataEditHistoryRecord = async (dataEditHistoryId) => {

        let dataEditHistoryRecord = await this.dataEditHistoryService.getSingleDataEditHistory(this.dataEditHistoryForSingleRecord, dataEditHistoryId);

        let resultsBoxFieldSlugsToBlocIds = {};

        // loop through all scanStationFields and check if the field type is results-box
        // also use this loop to remove unneeded hidden field values

        let changedData = {};
        let changedResultsBoxData = {};

        for (let i = 0; i < this.scanStation.scanStationObj.scanStationFields.length; i++) {

            let field = this.scanStation.scanStationObj.scanStationFields[i];

            let currentResultsBoxData = this.resultsBoxService.currentResultsBoxData;

            if (field.field_type === 'results-box') {

                resultsBoxFieldSlugsToBlocIds[this.scanStation.scanStationObj.scanStationFields[i].field_slug] = this.scanStation.scanStationObj.scanStationFields[i].resultsBoxStationAndFields.stationId;
                for (let resultsBoxEntry of this.convertObjectToArray(dataEditHistoryRecord.resultsBoxData[this.scanStation.scanStationObj.scanStationFields[i].resultsBoxStationAndFields.stationId])) {
                    if(currentResultsBoxData[field.field_slug] != null) {
                        for (let currentResultsBoxDataByField of this.convertObjectToArray(currentResultsBoxData[field.field_slug])) {
                            if (currentResultsBoxDataByField.cartolytics_entry_row_id == resultsBoxEntry.cartolytics_entry_row_id) {
                                for (let key in resultsBoxEntry) {
                                    if (resultsBoxEntry[key] != currentResultsBoxDataByField[key]) {

                                        if(changedResultsBoxData[this.scanStation.scanStationObj.scanStationFields[i].resultsBoxStationAndFields.stationId] == null) {
                                            changedResultsBoxData[this.scanStation.scanStationObj.scanStationFields[i].resultsBoxStationAndFields.stationId] = {};
                                        }

                                        changedResultsBoxData[this.scanStation.scanStationObj.scanStationFields[i].resultsBoxStationAndFields.stationId][key] = true;

                                        changedData[field.field_slug] = true;
                                    }
                                }

                            }
                        }
                    }
                }
            }

            if ((dataEditHistoryRecord.data[field.field_slug] != null && field.inFormHide == 1) || dataEditHistoryRecord.data[field.field_slug] == 'No Data') {
                delete dataEditHistoryRecord.data[field.field_slug]
            }

            if (dataEditHistoryRecord.data[field.field_slug] != null && dataEditHistoryRecord.data[field.field_slug] != this.scanStation.scanStationObj.dataToAdd[field.field_slug]) {
                changedData[field.field_slug] = true;
            }
        }

        const dialogRef = this.dialog.open(DataEditHistoryDialog, {
            data: {
                dataEditHistoryRecord: dataEditHistoryRecord,
                changedData: changedData,
                resultsBoxFieldSlugsToBlocIds: resultsBoxFieldSlugsToBlocIds,
                scanStation: this.scanStation,
                changedResultsBoxData: changedResultsBoxData
            }
        });

        this.overlayContainer.getContainerElement().classList.add('blocworx-popup');

        dialogRef.afterClosed().subscribe(result => {
            this.overlayContainer.getContainerElement().classList.remove('blocworx-popup');
        });
    }

}
