import { Component, Inject, Input, OnInit } from "@angular/core";
import { ManageDataService } from "@/angular-blocworx/components/manageData/manageData.service";
import { MatTableDataSource } from "@angular/material/table";

interface EntryData {
    user_id: string;
    created_at: string;
    updated_at: string;

    [key: string]: any;
}

@Component({
    selector: "look-up-data-results",
    templateUrl: "angular-blocworx/components/manageData/look-up-data/look-up-data-results.html",
    styleUrls: ["angular-blocworx/components/manageData/look-up-data/look-up-data.scss"],

})
export class LookUpDataResults implements OnInit {
    @Input() main;
    @Input() lc;

    noDataFound: boolean;
    dataManagerData: any;
    dataToLookUp: string;
    loadingSearch: boolean;
    openUpdatePopup: boolean = false;

    updateStationDynamicColumns: string[] = [];

    selectedStationData: any;
    stationId: any;
    entryId: any;
    entryData: any;

    // Columns for the data table
    displayedColumns: string[] = ["user_id", "created_at", "updated_at", "update", "edit"];

    // Array to store table data and dynamic columns
    tables: { dataSource: MatTableDataSource<EntryData>; dynamicColumns: string[] }[] = [];

    constructor(@Inject(ManageDataService) private ManageDataService: ManageDataService) {}

    ngOnInit() {
        this.loadingSearch = true;
        this.lc.loadingSearch = true;

        // Subscribes to the state changes in the ManageDataService to get the updated data
        this.ManageDataService.getState().subscribe((state: { noDataFound: boolean; dataManagerData: any[]; dataToLookUp: string }) => {
            this.noDataFound = state.noDataFound;
            this.dataManagerData = state.dataManagerData;
            this.dataToLookUp = state.dataToLookUp;

            this.noDataFound = !this.dataManagerData.data.length;

            this.tables = [];

            // For each entry in dataManagerData, create a separate table data source
            for (const data of this.dataManagerData.data) {
                if (data.stations && typeof data.stations === "object") {
                    for (const stationKey in data.stations) {
                        const station = data.stations[stationKey];

                        const entryDataArray: EntryData[] = [];
                        const dynamicColumnsForStation: string[] = [];

                        if (Array.isArray(station.dataResult)) {
                            for (const dataResult of station.dataResult) {
                                if (dataResult.entry_data && typeof dataResult.entry_data === "object") {
                                    const entryData: EntryData = dataResult.entry_data;
                                    entryDataArray.push(entryData);

                                    // Capture dynamic column names excluding static ones (user_id, created_at, updated_at)
                                    for (const key in entryData) {
                                        if (!["user_id", "created_at", "updated_at"].includes(key) && !dynamicColumnsForStation.includes(key)) {
                                            dynamicColumnsForStation.push(key);
                                        }
                                    }
                                }
                            }
                        }

                        // Create and store data source for each station, including its dynamic columns
                        this.tables.push({
                            dataSource: new MatTableDataSource<EntryData>(entryDataArray),
                            dynamicColumns: dynamicColumnsForStation,
                        });
                    }
                }
            }

            // Include static columns + dynamic columns that have valid data for the entire dataset
            this.displayedColumns = ["user_id", "created_at", "updated_at", "update", "edit"];
            this.loadingSearch = false;
            this.lc.loadingSearch = false;
        });
    }

    /**
     *
     * @param station
     * @param dynamicColumns
     * @param record
     * This method will be used to set the dynamic columns for the selected station when opening the update popup
     */
    openUpdateModal(station: any, dynamicColumns: string[], record: any) {
        this.selectedStationData = station;
        this.updateStationDynamicColumns = dynamicColumns;
        this.openUpdatePopup = true;

        this.stationId = record.scan_station_id;
        this.entryId = record.id;
        this.entryData = record.entry_data;
    }


    /**
     *
     * @param entryID
     * @param newData
     * @param stationID
     * This method is used to update data for the selected entry
     */
    async updateData(entryID: any, newData: any, stationID: any): Promise<void> {
        try {
            await this.ManageDataService.updateData(entryID, newData, stationID);
            alert("Data has been updated successfully");
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }

    /**
     *
     * @param entryID
     * This method deletes the selected entry from the data
     */
    async deleteData(entryID: any ): Promise<void> {
        try {
            await this.ManageDataService.deleteData(entryID);
            console.log("Record has been deleted successfully");
            alert("Record has been deleted successfully");

            this.selectedStationData.dataResult = this.selectedStationData.dataResult.filter(
                (item) => item.id !== this.entryId
            );
        } catch (error) {
            console.error("Error deleting record:", error);
        }
    }
}
