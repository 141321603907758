import {Component, Inject, OnInit, Input} from '@angular/core';


@Component({
    selector: 'template-exporter-create-edit-radio-button',
    templateUrl: 'angular-blocworx/components/fields/template-exporter/templates/create-edit/radio-button.html',
    styleUrls: ['angular-blocworx/components/fields/template-exporter/template-exporter.component.scss'],
})

export class TemplateExporterEditRadioButtonComponent implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {

    }

    /**
     * This code sets the field type for the purpose of the popup
     * @param activeField
     */
    setFieldPopUp(activeField) {
        this.fc.initialFieldParams={};
        this.scanStation.activeField = activeField;
    }
}