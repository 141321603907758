import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class TemplateExporterService {
    apiUrl: string;
    evervaultEnabledApiUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService,
                @Inject(AppSettingService) private appSettingService: AppSettingService) {

        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();

    }

    /**
     * This will load all the bloc's templates to be showing on
     * Choose template section in a bloc.
     *
     * @param scanStationId
     */
    loadScanStationTemplates = async (scanStationId): Promise<any> => {

        let headers = this.request.getHeaders();

        const params = new HttpParams().set('scanStationId', scanStationId);

        let response = this.http.get(
            `${this.evervaultEnabledApiUrl}load-scan-station-templates`,
            {params, headers}
        );

        return lastValueFrom(response)
    }

    /**
     * This will load all the bloc's templates to be showing on
     * Choose template section in a bloc.
     *
     * @param scanStationId
     */
    getSingleTemplateDetails = async (templateId): Promise<any> => {

        let headers = this.request.getHeaders();

        const params = new HttpParams().set('templateId', templateId);

        let response = this.http.get(
            `${this.evervaultEnabledApiUrl}get-single-template-details`,
            {params, headers}
        );

        return lastValueFrom(response)
    }


    /**
     * This will get the automatically chosen template file if applicable.
     *
     * @param scanStationId
     * @param dataToAdd
     * @param rowId
     */

    public getAutomaticallyChosenTemplateFile = async (scanStationId, dataToAdd, rowId, dataEditHistoryId = null, multipleTemplates = null) : Promise<any> => {

        let headers = this.request.getHeaders();

        const body = {
            scanStationId: scanStationId,
            dataToAdd: dataToAdd,
            rowId: rowId,
            dataEditHistoryId: dataEditHistoryId,
            multipleTemplates: multipleTemplates
        }

        const response = this.http.post(`${this.apiUrl}get-automatically-chosen-template-file`, body, {headers});

        return lastValueFrom(response)

    }

    /**
     * This is the main call that downloads a file using either row Id, data edit history Id, or current bloc data
     * It also contains the name of the template file we're going to use, and also the option of whether its a docx or pdf file
     * @param scanStationId
     * @param dataToAdd
     * @param chosenTemplateFile
     * @param pdfOrWord
     * @param rowId
     * @param emailAddresses
     * @param dataEditHistoryId
     */
    public templateDownload = async (scanStationId, dataToAdd, chosenTemplateFile, pdfOrWord='docx', rowId, emailAddresses, dataEditHistoryId = null): Promise<any> => {

        let body = {
            scanStationId: scanStationId,
            dataToAdd: dataToAdd,
            chosenTemplateFile: chosenTemplateFile,
            pdfOrWord: pdfOrWord,
            rowId: rowId,
            emailAddresses: emailAddresses,
            dataEditHistoryId: dataEditHistoryId
        };

        let headers = this.request.getHeaders();

        const response = this.http.post(`${this.apiUrl}template-download`, body, {headers, responseType: 'blob' as 'json'});

        return lastValueFrom(response)

    }

    /**
     * This is a small helper function to extract the email addresses from fields in the bloc that has data sitting in it
     * @param dataSource
     * @param scanStationFields
     */
    public buildMailToAddresses = (dataSource, scanStationFields) => {
        let emailAddress = [];
        angular.forEach(scanStationFields, function(field) {
            if(field.emailAddressForTemplateExporting == true) {
                emailAddress.push(dataSource[field.field_slug]);
            }
        });

        return emailAddress.join(',');
    }

}