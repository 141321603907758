import {
    Component,
    Inject,
    OnInit,
    Input,
    Renderer2
} from '@angular/core';
import {
    TemplateExporterServiceFoField
} from "@/angular-blocworx/components/fields/template-exporter/template-exporter.service";
import * as moment from "moment";
import {BaseFieldsComponent} from "@/angular-blocworx/components/fields/BaseFieldsComponent";
import {MatDialog} from "@angular/material/dialog";
import {OverlayContainer} from '@angular/cdk/overlay';
import {
    TemplateExporterService
} from "@/angular-blocworx/components/scanStation/template-exporter/template-exporter.service";

@Component({
    selector: 'template-exporter-in-form',
    templateUrl: 'angular-blocworx/components/fields/template-exporter/templates/in-form.html',
    styleUrls: ['angular-blocworx/components/fields/template-exporter/template-exporter.component.scss'],
})

export class TemplateExporterInFormComponent extends BaseFieldsComponent implements OnInit {

    @Input() field;
    @Input() scanStation;
    @Input() main;
    downloadUrl = null;
    selectedFileOutputFileName = null;
    filename = 'template.docx';
    templateDetails: any = {};
    showPdfOrDocxChoice: any = []

    constructor(
        @Inject(TemplateExporterService) private templateExporterService: TemplateExporterService,
        @Inject(TemplateExporterServiceFoField) private templateExporterServiceFoField: TemplateExporterServiceFoField,
        @Inject(Renderer2) private renderer: Renderer2,
    ) {
        super();

    }

    /**
     * Initializes the component.
     *
     * @returns {void}
     */
    async ngOnInit() {
    }

    /**
     * Here we load the template after someone clicks the button in the records
     * We either get the template automatically based on conditions or we get the template that was set
     * After we have our template we use existing functionality to generate the file
     * @param pdfOrWord
     */

    loadTemplate = async (pdfOrWord = null) => {

        let automaticallyChosenTemplate = false;
        // here we are checking if we are using the multipleFileTemplate option or just a single template
        if (
            this.field.multipleTemplates &&
            (Array.isArray(this.field.multipleTemplates)
                ? this.field.multipleTemplates.length > 1
                : typeof this.field.multipleTemplates === 'object' &&
                Object.keys(this.field.multipleTemplates).length > 1)
        ) {
            let chosenTemplate = await this.templateExporterService.getAutomaticallyChosenTemplateFile(this.scanStation.id, this.scanStation.scanStationObj.dataToAdd, null, null, this.field.multipleTemplates);
            this.templateDetails = chosenTemplate.templateFile;
            automaticallyChosenTemplate = true;
        }

        if (automaticallyChosenTemplate == false) {
            this.templateDetails = await this.templateExporterService.getSingleTemplateDetails(this.field.templateId);
        }

        if(pdfOrWord == null) {
            pdfOrWord = this.field.docxOrPdf;
        }
        let chosenTemplateFile = this.templateDetails.exported_filename;
        this.selectedFileOutputFileName = this.templateDetails.template_name;
        this.setSelectedDownloadDetails(this.templateDetails)

        let response = await this.templateExporterService.templateDownload(this.scanStation.id, this.scanStation.scanStationObj.dataToAdd, chosenTemplateFile, pdfOrWord, null, null, null);
        let blob = new Blob([response], {
            type: 'application/octet-stream'
        });

        let URL = window.URL || window.webkitURL;

        this.downloadUrl = URL.createObjectURL(blob);

        if (pdfOrWord != 'pdf' && this.selectedFileOutputFileName == null && chosenTemplateFile != 'automatically-chosen-template') {
            this.filename = this.scanStation.scanStationObj.stationName + '.' + chosenTemplateFile.split('.').pop();
        } else if (pdfOrWord != 'pdf' && this.selectedFileOutputFileName != null && chosenTemplateFile != 'automatically-chosen-template') {
            this.filename = this.selectedFileOutputFileName + '.' + chosenTemplateFile.split('.').pop();
        } else if (pdfOrWord == 'pdf' && this.selectedFileOutputFileName != null) {
            this.filename = this.selectedFileOutputFileName + '.pdf';
        } else {
            this.filename = this.scanStation.scanStationObj.stationName + '.pdf';
        }

        let element = await document.getElementById('downloadPdfBtn');

        this.renderer.setAttribute(element, 'href', this.downloadUrl);
        this.renderer.setAttribute(element, 'download', this.filename);

        element.click();
    }


    async ngAfterViewInit() {
    }

    /**
     * This function sets up the details, primarily the output file name, for the selected download
     * @param templateFile
     */
    setSelectedDownloadDetails = async (templateFile) => {
        if (templateFile.output_filename == null || templateFile.output_filename == '') {
            this.selectedFileOutputFileName = null;
        } else {
            this.selectedFileOutputFileName = templateFile.output_filename;

            // replace the [BW]field_slug[/BW] in the file name with data
            let dataObject = [];

            let singleRecordObject = this.scanStation.scanStationObj.dataToAdd;

            dataObject = Object.entries(singleRecordObject);

            for (let [index, val] of dataObject) {
                this.selectedFileOutputFileName = this.selectedFileOutputFileName.replaceAll(`[BW]${index}[/BW]`, val);
            }

        }

    }


}
