import {Injectable, Inject} from '@angular/core';
import angular from "angular";
import { downgradeInjectable } from '@angular/upgrade/static';
import {BaseFieldService} from "@/angular-blocworx/components/fields/BaseFieldService";
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from "rxjs";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})

export class TemplateExporterServiceFoField extends BaseFieldService {

    private _currentDay: moment.Moment;
    private _currentWeek: moment.Moment;
    private _currentMonth: Date;

    constructor(

        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {

        super(http, configuration, request);
    }


    /**
     * Here we get all the field parameter settings for this field
     */
    getInitialParams(): object {

        return {
            'templateId': {
                'validation': {
                    'required': false,
                }
            },
            'docxOrPdf': {
                'validation': {
                    'required': false,
                }
            },
            'buttonText': {
                'validation': {
                    'required': false,
                }
            },
            'multipleTemplates': {
                'validation': {
                    'required': false,
                }
            },
            'manualDocxorPdfChoice': {
                'validation': {
                    'required': false,
                }
            },
            'buttonColour': {
                'validation': {
                    'required': false,
                }
            },
            'enableTemplateExporterShowMore': {
                'validation': {
                    'required': false,
                }
            }
        };
    }

}

