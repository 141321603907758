import {Component, Inject, Input, Injectable} from '@angular/core';
import {TemplateExporterServiceFoField} from '@/angular-blocworx/components/fields/template-exporter/template-exporter.service';
import {FieldEditingService} from "@/angular-blocworx/components/fields/fieldEditing/field-editing.service";
import {BaseFieldsComponent} from "@/angular-blocworx/components/fields/BaseFieldsComponent";
import {TemplateExporterService} from "@/angular-blocworx/components/scanStation/template-exporter/template-exporter.service";
@Component({
    selector: 'template-exporter-create-edit-popup',
    templateUrl: 'angular-blocworx/components/fields/template-exporter/templates/create-edit/popup.html',
    styleUrls: ['angular-blocworx/components/fields/template-exporter/template-exporter.component.scss'],
})

@Injectable()

export class TemplateExporterCreateEditPopupComponent extends BaseFieldsComponent{

    @Input() fc;
    @Input() scanStation;
    templateList: any = {};
    selectedTemplateForMultipleTemplates: string

    fcInitialFieldParameterArrays: Record<string, any[]> = {};
    templatesByTemplateId: any = [];

    constructor(
        @Inject(TemplateExporterServiceFoField) private templateExporterServiceForField: TemplateExporterServiceFoField,
        @Inject(FieldEditingService) private fieldEditingService: FieldEditingService,
        @Inject(TemplateExporterService) private templateExporterService: TemplateExporterService){
        super();
    }

    async ngOnInit(): Promise<void> {

    }

    /**
     * Here we will get all the templates when someone opens the field popup
     */
    async ngAfterViewInit(): Promise<void> {
        this.templateList = await this.templateExporterService.loadScanStationTemplates(this.scanStation.id);
        for (let template of this.templateList) {
            this.templatesByTemplateId[template.id] = template;
        }

        await this.initialiseFieldParametersFromValues()
    }

    /**
     * This code initialises all the field parameters so there is nothing left undefined in the html
     */
    async initialiseFieldParametersFromValues(): Promise<void> {
        const defaultFieldParameterObject: object = this.templateExporterServiceForField.getInitialParams();
        this.fc.initialFieldParams = this.templateExporterServiceForField.initiateAllFieldParametersFromValues(this.fc.initialFieldParams, defaultFieldParameterObject);
        this.forceArrayFromObjectOrArray('multipleTemplates');

    }

    /**
     * This code adds template Ids to a list
     * @param fieldParameter
     * @param fieldId
     */

    addSingleFieldList = (fieldParameter, fieldId) => {
        this.fc.initialFieldParams[fieldParameter] = this.fieldEditingService.addSingleFieldList(this.fc.initialFieldParams[fieldParameter], fieldId);
        this.forceArrayFromObjectOrArray(fieldParameter);
    }

    /**
     * This code removes a template Id from a list
     * @param fieldParameter
     * @param index
     */

    removeFromSingleFieldList = (fieldParameter, index) => {
        this.fc.initialFieldParams[fieldParameter] = this.fieldEditingService.removeFromFieldList(this.fc.initialFieldParams[fieldParameter],index);
        this.forceArrayFromObjectOrArray(fieldParameter);
    }


    /**
     * This code is a simple converter from object to array for ngFor
     */
    convertObjectToArray(object:object): any[] {
        if (object != null) {
            return Object.values(object);
        }
    }

    /**
     * This forces an object to be an array, sometimes needed for ngFor
     * @param fieldParameter
     */

    forceArrayFromObjectOrArray = (fieldParameter) => {
        if(Array.isArray(this.fc.initialFieldParams[fieldParameter])) {
            this.fcInitialFieldParameterArrays[fieldParameter] = this.fc.initialFieldParams[fieldParameter];
        } else {
            this.fcInitialFieldParameterArrays[fieldParameter] = Object.values(this.fc.initialFieldParams[fieldParameter]);
        }
    }

}
