import {Component, Inject, OnInit, Input, Renderer2} from '@angular/core';
import {
    TemplateExporterService
} from "@/angular-blocworx/components/scanStation/template-exporter/template-exporter.service";
import {
    TemplateExporterServiceFoField
} from "angular-blocworx/components/fields/template-exporter/template-exporter.service";


import {ScanStationService} from "@/angular-blocworx/components/scanStation/scan-station-service";

@Component({
    selector: 'template-exporter-in-data',
    templateUrl: 'angular-blocworx/components/fields/template-exporter/templates/in-data.html',
    styleUrls: ['angular-blocworx/components/fields/template-exporter/template-exporter.component.scss'],
})

export class TemplateExporterInDataComponent implements OnInit {

    @Input() field;
    @Input() scanStation;
    @Input() row;
    @Input() main;
    @Input() data;
    @Input() fc;
    downloadUrl = null;
    selectedFileOutputFileName = null;
    filename = 'template.docx';
    templateDetails: any = {};
    showPdfOrDocxChoice: any = [];

    constructor(
        @Inject(TemplateExporterService) private templateExporterService: TemplateExporterService,
        @Inject(Renderer2) private renderer: Renderer2,
        @Inject(ScanStationService) private scanStationService: ScanStationService
    ) {

    }

    ngOnInit(): void {

    }

    /**
     * Here we load the template after someone clicks the button in the records
     * We either get the template automatically based on conditions or we get the template that was set
     * After we have our template we use existing functionality to generate the file
     * @param pdfOrWord
     */

    loadTemplate = async (pdfOrWord = null) => {

        let automaticallyChosenTemplate = false;
        // here we are checking if we are using the multipleFileTemplate option or just a single template
        if (
            this.field.multipleTemplates &&
            (Array.isArray(this.field.multipleTemplates)
                ? this.field.multipleTemplates.length > 1
                : typeof this.field.multipleTemplates === 'object' &&
                Object.keys(this.field.multipleTemplates).length > 1)
        ) {
            let chosenTemplate = await this.templateExporterService.getAutomaticallyChosenTemplateFile(this.scanStation.id, this.scanStation.scanStationObj.dataToAdd, this.row.cartolytics_entry_row_id, null, this.field.multipleTemplates);
            this.templateDetails = chosenTemplate.templateFile;
            automaticallyChosenTemplate = true;
        }

        if (automaticallyChosenTemplate == false) {
            this.templateDetails = await this.templateExporterService.getSingleTemplateDetails(this.field.templateId);
        }

        if (pdfOrWord == null) {
            pdfOrWord = this.field.docxOrPdf;
        }
        let chosenTemplateFile = this.templateDetails.exported_filename;
        this.selectedFileOutputFileName = this.templateDetails.template_name;

        this.setSelectedDownloadDetails(this.templateDetails)

        let response = await this.templateExporterService.templateDownload(this.scanStation.id, null, chosenTemplateFile, pdfOrWord, this.row.cartolytics_entry_row_id, null, null);

        let blob = new Blob([response], {
            type: 'application/octet-stream'
        });

        let URL = window.URL || window.webkitURL;

        this.downloadUrl = URL.createObjectURL(blob);

        if (pdfOrWord != 'pdf' && this.selectedFileOutputFileName == null && chosenTemplateFile != 'automatically-chosen-template') {
            this.filename = this.scanStation.scanStationObj.stationName + '.' + chosenTemplateFile.split('.').pop();
        } else if (pdfOrWord != 'pdf' && this.selectedFileOutputFileName != null && chosenTemplateFile != 'automatically-chosen-template') {
            this.filename = this.selectedFileOutputFileName + '.' + chosenTemplateFile.split('.').pop();
        } else if (pdfOrWord == 'pdf' && this.selectedFileOutputFileName != null) {
            this.filename = this.selectedFileOutputFileName + '.pdf';
        } else {
            this.filename = this.scanStation.scanStationObj.stationName + '.pdf';
        }

        let element = await document.getElementById('downloadPdfBtn');

        this.renderer.setAttribute(element, 'href', this.downloadUrl);
        this.renderer.setAttribute(element, 'download', this.filename);

        element.click();
    }

    /**
     * This function sets up the details, primarily the output file name, for the selected download
     * @param templateFile
     */
    setSelectedDownloadDetails = async (templateFile) => {
        if (templateFile.output_filename == null || templateFile.output_filename == '') {
            this.selectedFileOutputFileName = null;
        } else {
            this.selectedFileOutputFileName = templateFile.output_filename;

            // replace the [BW]field_slug[/BW] in the file name with data
            let dataObject = [];

            let singleRecordObject = await this.scanStationService.getSingleRecordByRecordId(this.row.cartolytics_entry_row_id);
            dataObject = Object.entries(singleRecordObject.data);

            for (let [index, val] of dataObject) {
                this.selectedFileOutputFileName = this.selectedFileOutputFileName.replaceAll(`[BW]${index}[/BW]`, val);
            }

        }

    }

}